/* Base transitions */
.transition-base {
  transition: all 250ms ease-in-out;
}

/* Fade animations */
.fade-enter {
  opacity: 0;
  transform: translateY(10px);
}

.fade-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 250ms ease-in-out, transform 250ms ease-in-out;
}

.fade-exit {
  opacity: 1;
  transform: translateY(0);
}

.fade-exit-active {
  opacity: 0;
  transform: translateY(10px);
  transition: opacity 250ms ease-in-out, transform 250ms ease-in-out;
}

.fade-appear {
  opacity: 0;
  transform: translateY(10px);
}

.fade-appear-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 250ms ease-in-out, transform 250ms ease-in-out;
}

/* Hardware acceleration */
.fade-enter,
.fade-enter-active,
.fade-exit,
.fade-exit-active,
.fade-appear,
.fade-appear-active {
  will-change: opacity, transform;
}

/* Slide animations */
.slide-enter {
  opacity: 0;
  transform: translateX(-20px);
}

.slide-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 250ms ease-in-out, transform 250ms ease-in-out;
}

.slide-exit {
  opacity: 1;
  transform: translateX(0);
}

.slide-exit-active {
  opacity: 0;
  transform: translateX(20px);
  transition: opacity 250ms ease-in-out, transform 250ms ease-in-out;
}

/* Scale animations */
.scale-enter {
  opacity: 0;
  transform: scale(0.95);
}

.scale-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 250ms ease-in-out, transform 250ms ease-in-out;
}

.scale-exit {
  opacity: 1;
  transform: scale(1);
}

.scale-exit-active {
  opacity: 0;
  transform: scale(0.95);
  transition: opacity 250ms ease-in-out, transform 250ms ease-in-out;
}

/* Utility classes */
.hover-lift {
  transition: transform 250ms ease-in-out;
}

.hover-lift:hover {
  transform: translateY(-2px);
}

.hover-scale {
  transition: transform 250ms ease-in-out;
}

.hover-scale:hover {
  transform: scale(1.05);
}

.hover-opacity {
  transition: opacity 250ms ease-in-out;
}

.hover-opacity:hover {
  opacity: 0.8;
}

/* Simple page transition */
.page-content {
  animation: simpleFade 0.2s ease;
}

@keyframes simpleFade {
  from { opacity: 0; }
  to { opacity: 1; }
} 