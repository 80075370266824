@import '../styles/transitions.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .backface-hidden {
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
  }
  
  .rotate-y-180 {
    transform: rotateY(180deg);
  }

  .animate-rainbow-text {
    @apply bg-gradient-to-r;
    animation: rainbow 12s linear infinite;
    background-size: 300% 100%;
  }

  .animate-rainbow-stroke {
    animation: rainbow-stroke 12s linear infinite;
  }

  .animate-rainbow-stop-1 {
    animation: rainbow-stop-1 12s linear infinite;
  }

  .animate-rainbow-stop-2 {
    animation: rainbow-stop-2 12s linear infinite;
  }

  .animate-rainbow-stop-3 {
    animation: rainbow-stop-3 12s linear infinite;
  }

  .animate-spin-slow {
    animation: spin 20s linear infinite;
  }

  .animate-rotate-y {
    animation: rotate-y 20s linear infinite;
    transform-style: preserve-3d;
  }

  /* Accessibility utilities for links */
  .a11y-link {
    @apply text-blue-400 hover:text-blue-300 focus:text-blue-300 transition-colors no-underline;
    text-decoration: none;
    position: relative;
  }
  
  /* Non-color indicator for links in text for better accessibility */
  .a11y-link::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: currentColor;
    bottom: -1px;
    left: 0;
    transform-origin: center;
    transform: scaleX(0);
    transition: transform 0.3s ease-out;
  }
  
  .a11y-link:hover::after,
  .a11y-link:focus-visible::after {
    transform: scaleX(1);
  }
  
  /* High contrast mode indicators */
  @media (forced-colors: active) {
    .a11y-link {
      text-decoration: underline;
    }
    
    .a11y-link::after {
      display: none;
    }
  }
}

:root {
  --foreground-rgb: 255, 255, 255;
  --background-rgb: 10, 10, 10;
  --nav-hover: 0;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

@layer base {
  html {
    -webkit-tap-highlight-color: transparent;
  }
  
  body {
    @apply bg-[#0A0A0A] text-white antialiased overflow-x-hidden;
    touch-action: manipulation;
    transition: backdrop-filter 100ms ease-out;
  }

  body::before {
    content: '';
    position: fixed;
    inset: 0;
    backdrop-filter: blur(calc(var(--nav-hover) * 10px));
    pointer-events: none;
    z-index: 90;
    transition: backdrop-filter 100ms ease-out;
  }

  /* Fluid Typography */
  h1 { @apply text-3xl font-bold leading-tight; }
  h2 { @apply text-2xl font-semibold leading-tight; }
  h3 { @apply text-xl font-semibold leading-snug; }
  p { @apply text-base leading-relaxed; }
  
  /* Blog article links styling with enhanced accessibility */
  article a {
    @apply text-blue-400 hover:text-blue-300 focus:text-blue-300 transition-colors;
    text-decoration: underline;
    text-underline-offset: 2px;
    position: relative;
  }
  
  /* Ensure links are distinguishable within text blocks by more than just color */
  article a:hover, 
  article a:focus-visible {
    @apply text-blue-300;
    text-decoration-thickness: 2px;
    outline: 2px solid transparent;
    outline-offset: 2px;
  }
  
  /* Improve link focus visibility */
  article a:focus-visible {
    @apply ring-2 ring-blue-300 ring-offset-1 ring-offset-[#0A0A0A];
    outline: none;
  }
  
  /* Skip navigation link for keyboard users */
  .skip-nav {
    @apply sr-only focus:not-sr-only focus:absolute focus:top-2 focus:left-2 
           focus:z-[9999] focus:px-4 focus:py-2 focus:bg-blue-800 focus:text-white 
           focus:no-underline focus:rounded-md focus:outline-white;
  }
}

@layer components {
  /* Modern Card */
  .card {
    @apply bg-white/5 backdrop-blur-sm rounded-xl transition-all duration-300;
    padding: var(--space-2);
  }

  .card:hover {
    @apply bg-white/10 transform-gpu scale-[1.02];
  }

  /* Modern Button */
  .btn {
    @apply px-4 py-2 rounded-lg font-medium transition-all duration-100
           inline-flex items-center justify-center gap-2;
  }

  .btn-primary {
    @apply bg-white text-black hover:bg-gray-100 active:bg-gray-200;
  }

  .btn-secondary {
    @apply bg-white/10 text-white hover:bg-white/20 active:bg-white/30;
  }

  /* Modern Form Controls */
  .form-input {
    @apply w-full px-4 py-2 bg-white/5 rounded-lg border border-white/10
           focus:outline-none focus:border-white/30 transition-all duration-100;
  }

  .form-label {
    @apply block text-sm font-medium mb-2 text-gray-300;
  }

  /* Grid Layout */
  .grid-8 {
    display: grid;
    gap: var(--space-2);
  }
}

/* Custom Scrollbar */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  @apply bg-black/20 backdrop-blur-sm;
}

::-webkit-scrollbar-thumb {
  @apply bg-white/10 rounded-full hover:bg-white/20 transition-colors;
}

/* Focus States */
:focus-visible {
  @apply outline-none ring-2 ring-white/50 ring-offset-2 ring-offset-black;
}

/* Selection */
::selection {
  @apply bg-white/20 text-white;
}

/* Keyboard Navigation */
[data-keyboard-nav='true'] *:focus {
  @apply ring-2 ring-white/50 ring-offset-2 ring-offset-black outline-none;
}

@keyframes gradient-x {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.animate-gradient-x {
  background-size: 200% 100%;
  animation: gradient-x 3s ease infinite;
}

@keyframes rainbow {
  0% { background-image: linear-gradient(to right, #FF1E1E, #FF9C1E, #FFF01E); }
  20% { background-image: linear-gradient(to right, #1EFF1E, #1EFF9C, #1EFFF0); }
  40% { background-image: linear-gradient(to right, #1E1EFF, #9C1EFF, #F01EFF); }
  60% { background-image: linear-gradient(to right, #FF1E9C, #FF1EF0, #FF1E1E); }
  80% { background-image: linear-gradient(to right, #1EFFF0, #1E1EFF, #9C1EFF); }
  100% { background-image: linear-gradient(to right, #FF1E1E, #FF9C1E, #FFF01E); }
}

@keyframes rainbow-stop-1 {
  0% { stop-color: #FF1E1E; }
  20% { stop-color: #1EFF1E; }
  40% { stop-color: #1E1EFF; }
  60% { stop-color: #FF1E9C; }
  80% { stop-color: #1EFFF0; }
  100% { stop-color: #FF1E1E; }
}

@keyframes rainbow-stop-2 {
  0% { stop-color: #FF9C1E; }
  20% { stop-color: #1EFF9C; }
  40% { stop-color: #9C1EFF; }
  60% { stop-color: #FF1EF0; }
  80% { stop-color: #1E1EFF; }
  100% { stop-color: #FF9C1E; }
}

@keyframes rainbow-stop-3 {
  0% { stop-color: #FFF01E; }
  20% { stop-color: #1EFFF0; }
  40% { stop-color: #F01EFF; }
  60% { stop-color: #FF1E1E; }
  80% { stop-color: #9C1EFF; }
  100% { stop-color: #FFF01E; }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes luxury-gradient {
  0% { background-image: linear-gradient(to right, #4FACFE, #00F2FE); }
  33% { background-image: linear-gradient(to right, #43E97B, #38F9D7); }
  66% { background-image: linear-gradient(to right, #FAD961, #F7CE68); }
  100% { background-image: linear-gradient(to right, #4FACFE, #00F2FE); }
}

.animate-luxury-gradient {
  animation: luxury-gradient 8s ease infinite;
  background-size: 300% 100%;
}

@keyframes rotate-y {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(360deg);
  }
}

@keyframes elegant-gradient {
  0% { background-image: linear-gradient(to right, #2193b0, #6dd5ed); }
  25% { background-image: linear-gradient(to right, #6dd5ed, #2193b0); }
  50% { background-image: linear-gradient(to right, #56ab2f, #a8e063); }
  75% { background-image: linear-gradient(to right, #a8e063, #56ab2f); }
  100% { background-image: linear-gradient(to right, #2193b0, #6dd5ed); }
}

.animate-elegant-gradient {
  animation: elegant-gradient 12s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  background-size: 200% 100%;
}

/* Animation for gradient text shimmer effect - KEPT FOR REFERENCE, NOT USED IN MOBILE MENU */
@keyframes slowShimmer {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

.animate-slowShimmer {
  animation: slowShimmer 3s ease-in-out infinite;
}

/* Static Steel Gradient Text - No Animation */
.text-steel-gradient {
  background: linear-gradient(to right, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.8));
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

/* Text gradient for page titles - 100% white, no animation */
.text-static-gradient {
  background: linear-gradient(to right, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.8));
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  background-size: 200% 100%;
  background-position: 50% 0; /* Center the gradient */
}

/* Mobile Header Fixes */
@media (max-width: 768px) {
  body::before {
    content: none !important; /* Remove the potential gradient overlay */
    backdrop-filter: none !important;
  }

  body {
    background: #0A0A0A !important; /* Ensure solid background on mobile */
  }

  .mobile-header-container {
    -webkit-tap-highlight-color: transparent;
    touch-action: manipulation;
  }

  /* Improve touch targets */
  button, a {
    touch-action: manipulation;
  }
}

/* Fix gradient effect issues */
.mobile-header-container button {
  position: relative;
  z-index: 101;
}

/* Custom Image Fill Class - For consistent hydration */
.image-fill {
  position: absolute !important;
  height: 100% !important;
  width: 100% !important;
  left: 0 !important;
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  color: transparent !important;
  object-fit: cover !important;
} 